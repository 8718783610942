<template>
    <div>
        <v-toolbar color="primary" dark flat>
            <v-btn icon @click="goBack">
                <v-icon large>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>{{$t('survey.completed.title')}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <loading v-if="isLoading"/>
        <div v-if="!isLoading" class="pb-10">
            <list-completed-surveys @onOpenSurvey="setCompletedSurvey" :completed-surveys="completedSurveys"/>
            <no-surveys-available v-if="completedSurveys.length === 0" :text="$t('survey.completed.empty')"/>
        </div>
        <modal-survey-completed :survey="completedSurvey" v-if="onShowCompletedSurveyModal"/>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import ListCompletedSurveys from '../../../../components/Surveys/Completed/ListCompletedSurveys';
import NoSurveysAvailable from '../../../../components/Surveys/NoSurveysAvailable';
import ModalSurveyCompleted from '../../../../components/Surveys/Modals/ModalSurveyCompleted';

export default {
    name: 'SurveysCompleted',
    components: {ModalSurveyCompleted, NoSurveysAvailable, ListCompletedSurveys},
    data: () => ({
        isLoading: false,
        completedSurvey: null
    }),
    computed: {
        ...mapState('surveys', ['completedSurveys']),
        ...mapState('modals', ['onShowCompletedSurveyModal'])
    },
    methods: {
        setCompletedSurvey(val) {
            this.completedSurvey = val;
        },
        goBack() {
            this.$router.back();
        },
        fetchCompletedSurveys() {
            this.isLoading = true;
            this.$store.dispatch('surveys/getSurveysByStatus', 'completed').then(() => {
                this.isLoading = false;
            });
        },
        fetchCompletedSurveysFromStorage() {
            this.isLoading = true;
            this.$store.dispatch('surveys/getCompletedSurveysFromIndexedDB').then(() => {
                this.isLoading = false;
            });
        }
    },
    created() {
        if (this.isOnline) {
            this.fetchCompletedSurveys();
        }
        if (!this.isOnline) {
            this.fetchCompletedSurveysFromStorage();
        }
    }
};
</script>
