<template>
    <v-list><!-- :to="{name: 'survey-detail', params: {'surveyId': survey.id}}" -->
        <v-list-item link @click="onOpenSurvey(survey)" v-for="(survey, index) in completedSurveys" :key="survey.id"
                     class="u-border-bottom"
                     :class="{'u-border-bottom-none': (index + 1) === completedSurveys.length }">
            <v-list-item-avatar>
                <v-avatar color="primary" :size="40">
                    <span class="white--text"
                          style="font-size:11px; font-weight: 700">{{ survey.createdAt | formatDateAlternate }}</span>
                </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ getLocalizedValue(survey.title) }}</v-list-item-title>
                <v-list-item-subtitle>{{ survey.ship ? survey.ship : $t('surveys.n_a_ship') }} /
                    {{ survey.imo ? survey.imo : 'N/A IMO' }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <status :status="survey.status"/>
            </v-list-item-action>
        </v-list-item>
    </v-list>
</template>

<script>
import Status from '../../UI/Status';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'ListCompletedSurveys',
    components: {Status},
    mixins: [GetLocalizedValue],
    props: {
        completedSurveys: {
            type: Array,
            required: true
        }
    },
    methods: {
        onOpenSurvey(survey) {
            this.$emit('onOpenSurvey', survey);
            this.$store.dispatch('modals/toggleModal', 'onShowCompletedSurveyModal');
        }
    }
};
</script>
