<template>
    <div class="status" style="display:inline-block;"
         :class="className">
        <span>
            {{ status | formatStatus | t }}
        </span>
    </div>
</template>

<script>
export default {
    name: "Status",
    props: {
        status: {
            type: String
        }
    },
    computed: {
        className() {
            switch (this.status) {
                case "SUBMITTED":
                    return "status--submitted";
                case "UNSUBMITTED":
                    return "status--not-submitted";
                case "CLOSED":
                    return "status--closed";
                case "OPEN":
                case "PARTIALLY_SUBMITTED":
                    return "status--open";

            }
        }
    }
};
</script>
