<template>
    <div class="text-center py-4">
        <v-icon :size="60" class="mb-2" color="primary">hourglass_empty</v-icon>
        <br />
        <small>{{text}}</small>
    </div>
</template>

<script>
export default {
    name: 'NoSurveysAvailable',
    props: {
        text: {
            type: String
        }
    }
};
</script>
