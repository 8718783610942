<template>
    <v-dialog
        v-model="dialog"
        max-width="290"
    >
        <v-card>
            <v-card-title class="headline text-center d-block">{{ $t('modal.survey_completed.title') }}</v-card-title>

            <v-card-text>
                <div class="text-center">
                    <v-icon size="70" class="mb-2" color="primary">check</v-icon>
                    <p>{{
                            $t("modal.survey_completed.body", {
                                title: getLocalizedValue(survey.title),
                                date: $options.filters.formatDateWithTime(survey.createdAt)
                            })
                        }}</p>
                    <v-btn color="primary" min-width="150" outlined rounded @click="onCloseModal">
                        {{ $t("modal.basic.close") }}
                    </v-btn>
                </div>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'ModalSurveyCompleted',
    mixins: [
        GetLocalizedValue
    ],
    props: {
        survey: {
            required: true
        }
    },
    data: () => ({
        dialog: true
    }),
    methods: {
        onCloseModal() {
            this.$store.dispatch('modals/closeModal');
        }
    }
};
</script>
